@use '@angular/material' as mat;
@import "var";
@import "@angular/material/theming";
@import "../partials/_mixins.scss";

:root {

    --color-primary: rgba(92, 119, 255);
    --background-app-bar: #{$bg1};

}

body {
    letter-spacing: normal;
}

.mat-mdc-table {
    .mat-mdc-header-row {
        color: rgb(var(--text-secondary));
    }
}

// login page
vex-login {
    .card {
        background-color: #005ca9;

        .caption  {
            color: var(--color-primary-contrast) !important;
        }
        .mdc-checkbox__background {
            color: var(--color-primary-contrast) !important;
            border-color: var(--color-primary-contrast) !important;
        }

        .mat-mdc-checkbox .mdc-form-field {
            color: white  !important;    
            &>label {
                color: var(--color-primary-contrast) !important;
            }
        }

        .mat-mdc-form-field-error {
            color: #{$error-contrast};
        }
    }

    .mat-mdc-raised-button.mat-primary {
        background-color: red !important;
    }
}

.sidenav .mat-drawer-inner-container {
    background: none;
}

.mat-mdc-paginator {
    .mat-mdc-paginator-range-actions {
        .paginator-btn {
            &.disabled {
                color: white;
            }
        }
    }
}

.footer {
    background: #0361b2 !important;
  
    .footer-back {
        a {
            color: white;
        }
    }
}


.attendance-btn {
    &.mat-icon.in {
        color: var(--btn-green);
    }
    &.mat-icon.out {
        color: var(--btn-red);
    }
  }


  vex-toolbar-notifications-dropdown {
    .btn-set-all-read {
        color: var(--color-primary-contrast) !important;
    }
  }

.toolbar .mat-mdc-text-field-wrapper{
    border-radius: 4px !important;
    padding: .1em .5em !important;
}

